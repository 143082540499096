export const PHRASES = [
    'And now you don\'t need to. Here at Fenton\'s Stables and Horse Ranch, we guarantee our horses have a smaller penis than a human man.',
    'Let me take a video of you saying you\'re going to kill the president.',
    'Right Now, What I Need, Is For You To Climb Down Out Of My Ass. Can You Do That? Will You Do That For Me, Honey?',
    'I Am Not In Danger, Skyler. I Am The Danger. A Guy Opens His Door And Gets Shot, And You Think That Of Me? No! I Am The One Who Knocks!',
    'Smoking Marijuana, Eating Cheetos, And Masturbating Do Not Constitute Plans In My Book.',
    'Goodbye everyone, I\'ll remember you all in therapy.',
    'You can\'t fool me. I listen to public radio!',
    'Is this the Krusty Krab? No, this is Patrick.',
    'Once upon a time there was an ugly barnacle. He was so ugly that everyone died. The end!',
    'He told me that the life of my dreams Would be promised and someday be mine, He told me that my power would grow Like the grapes that thrive on the vine',
    "Life moves pretty fast. If you don't stop and look around once in a while, you could miss it.",
    "You have to see the good in things, in people. You have to see the bright side.",
    "The greatest trick the devil ever pulled was convincing the world he didn't exist.",
    "You're going to have to make a choice. Do you want to be a victim or a gangster?",
    "Some people can't believe in themselves until someone else believes in them first.",
    "It's a dangerous business, Frodo, going out your door. You step onto the road, and if you don't keep your feet, there's no knowing where you might be swept off to.",
    "Remember who you are. You are my son and the one true king. Remember who you are.",
    "Nobody exists on purpose, nobody belongs anywhere, everybody's going to die. Come watch TV.",
    "I'm not a hero. I'm a high-functioning sociopath. Merry Christmas!",
    "Time is the fire in which we burn. Stop the future. I want to get off.",
    "To see the world, things dangerous to come to, to see behind walls, draw closer, to find each other, and to feel.",
    "In my world, everyone's a pony, and they all eat rainbows and poop butterflies.",
    "Life's a piece of shit, when you look at it. Life's a laugh and death's a joke, it's true.",
    "Some people say you're gonna break me, some people say that you're gonna brake me.",
    "Barely coping, as she glides along the boating lake. Slipping almost under completely.",
    "Drown in the irony, that most things are killing me. So, baptize my hope, in this infinite symphony.",
    "Where all my struggles gonna matter, cause I'm not getting any younger. Fear is such a sad way to live.",
    "I've been running through the jungle, I've been running with the wolves to get to you.",
    "If I could, I would shrink myself and sink through your skin to your blood cells and remove whatever makes you hurt.",
    "I'm beginning to feel like a Rap God, Rap God. All my people from the front to the back nod, back nod.",
    "Guess who's back, back again. Shady's back, tell a friend. Guess who's back, guess who's back, guess who's back.",
    "It's not who I am underneath, but what I do that defines me. So, what do you do?",
    "When you're lost out there and you're all alone, a light is waiting to carry you home.",
    "If you wanna make the world a better place, take a look at yourself, then make a change.",
    "Screaming at my father and he told me it ain't Christ-like. But nobody never tell you when you're being like Christ.",
    "We are all a little weird and life's a little weird. And when we find someone whose weirdness is compatible with ours, we join up with them and fall in mutual weirdness and call it love.",
    "The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle.",
    "Life is too short to wake up with regrets. So love the people who treat you right, forgive the ones who don't, and believe that everything happens for a reason.",
    "I feel like I'm too busy writing history to read it. My past behind me like a ponytail, I'm ahead of myself.",
    "And the saddest fear comes creeping in, that you never loved me or her or anyone or anything.",
    "I'll be your clown behind the glass, go ahead and laugh 'cause it's funny, I would too if I saw me.",
    "You're just like an angel, your skin makes me cry. You float like a feather in a beautiful world.",
    "All in all it's just another brick in the wall. All in all you're just another brick in the wall.",
    "If I die young, bury me in satin. Lay me down on a bed of roses. Sink me in the river at dawn, send me away with the words of a love song.",
    "Well, I don't know why I came here tonight. I got the feeling that something ain't right.",
    "When I find myself in times of trouble, Mother Mary comes to me, speaking words of wisdom, let it be.",
    "You may say I'm a dreamer, but I'm not the only one. I hope someday you'll join us, and the world will be as one.",
    "Somebody once told me the world is gonna roll me. I ain't the sharpest tool in the shed.",
    "If I ruled the world, every day would be the first day of spring. Every heart would have a new song to sing.",
    "Look at this stuff, isn't it neat? Wouldn't you think my collection's complete? Wouldn't you think I'm the girl, the girl who has everything?",
    "I'm kind of a big deal. People know me. I'm very important. I have many leather-bound books and my apartment smells of rich mahogany.",
    "I'm not superstitious, but I am a little stitious.",
    "I am serious. And don't call me Shirley.",
    "The first rule of Fight Club is: You do not talk about Fight Club.",
    "You can't handle the truth! Son, we live in a world that has walls, and those walls have to be guarded by men with guns.",
    "One ring to rule them all, one ring to find them, one ring to bring them all and in the darkness bind them.",
]